//GENERATED AS COPY OF ../../DecORM/utils/Auth.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.

import {User} from "~client/models";
import { RolesResolvers } from "~client/configuration";

/**Uses a user's roles to calculate the list of resolvers it has. Modifies the same user.*/
export function processResolvers(user: User) {
    if (!user.roles) {
        user.roles = [];
    }

    if (user.resolvers && user.resolvers.length >= 1) {
        return
    }

    user.rolesSet = new Set(user.roles);

    let resolverSet = new Set<number>();

    for (const role of user.roles) {
        for (const resolver of RolesResolvers[role]) {
            resolverSet.add(resolver);
        }
    }

    console.log("at processResolvers");

    user.resolvers = Array.from(resolverSet);
    user.resolversSet = resolverSet;
}

