import { Component, createContext, Dispatch, ReactElement, SetStateAction, useContext, useState } from "react";
import { Modal } from "react-bootstrap";

/**State data for the global modal.
 * Controls what the modal currently shows.
*/
export class ModalState {
    constructor({ show = false,
        page = "",
        body = null,
        footer = null,
        header,
        modal,
        value=null,
        title = "Modal Title",
    }: { show?: boolean, page?: string, body?: ReactElement| React.FunctionComponent, footer?: ReactElement, header?: ReactElement, modal?: ReactElement, title?: string, value?:any }) {
        this.show = show;
        this.page = page;
        this.title = title;

        this.body = body;
        this.footer = footer;
        if (header) {
            this.header = header;
        } else {
            this.header = <Modal.Header closeButton><Modal.Title>{title}</Modal.Title></Modal.Header>
        }

        this.value = value;

        if (modal) {
            this.modal = modal;
        }
    }

    /**Shows the modal if true. Hides if false. */
    show: boolean;

    page: string;

    /**Change the modal state. Immutable.
     * @example modalState.set({ ...modalState, show: false })
     */
    set: Dispatch<SetStateAction<ModalState>>; //(modalState:ModalState)=>{} 

    /**Modal title to show in default header */
    title: string;

    /**React element composing the entire modal itself. Overwrites header, body, and footer.
     * @example <Modal show={modalState.show} onHide={function () { modalState.set({ ...modalState, show: false }) }}>
     * <Modal.Header closeButton><Modal.Title>{this.title}</Modal.Title></Modal.Header>
     * <Modal.Body>Body</Modal.Body>
     * <Modal.Footer>Footer</Modal.Footer>
     * </Modal>
     * */
    modal: ReactElement = null;
    /**React element composing the modals header
     * @example <Modal.Header closeButton><Modal.Title>{this.title}</Modal.Title></Modal.Header>
    */
    header: ReactElement;// = <Modal.Header closeButton><Modal.Title>{this.title}</Modal.Title></Modal.Header>;
    /**React element composing the modals body
     * @example <Modal.Body>Body</Modal.Body>
     * */
    body: ReactElement | React.FunctionComponent | Component; //ReactElement| JSX.Element | React.FunctionComponent; //= <Modal.Body>Body</Modal.Body>;
    /**React element composing the modals footer
     * @example <Modal.Footer>Footer</Modal.Footer>
     * */
    footer: ReactElement; //= <Modal.Footer>Footer</Modal.Footer>;//null

    /**Generic data implemented by each modal separately*/
    value: any;
}

export const ModalContext = createContext<ModalState>(new ModalState({})); //window.localStorage.getItem("AUTH") || {});//Can set defaultValue apparently?

function TheModal() {

    const modalState = useContext(ModalContext);

    if (modalState.modal) {
        return modalState.modal;
    }

    return (
        <Modal show={modalState.show} onHide={function () { modalState.set({ ...modalState, show: false }) }}>
            <>
            {modalState.header}
            {modalState.body}
            {modalState.footer}
            </>
        </Modal>
    )
}

export const ModalWrapper = function ({ children = null }: { children: any }) { //{props:{children}}:{props?:{children?:ReactElement}}) {

    const [modalState, setModalState] = useState(new ModalState({}));

    return (
        <ModalContext.Provider value={{ ...modalState, set: setModalState }}>
            <TheModal />
            {children}
        </ModalContext.Provider>
    )

}