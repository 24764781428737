//GENERATED AS COPY OF ../../models/ServiceCall.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { RootDatabaseItem } from "./RootDatabaseItem" //"./RootDatabaseItem";
import Big from "big.js"
import { Employee, ModelFileOutput, Store, WorkRecord, Issue } from "~client/models";
import { IsEmail, IsPhoneNumber, MinLength, ValidateNested } from "class-validator";
import { Transform, Type } from "class-transformer";
import { GET_SERVICE_CALLS } from "AllGraphQLQueries";

/**Current payment status for a service call.*/
export enum PaymentStatus {
  /**This service call is not yet ready to be performed. */
  SCHEDULED = 0,//What should this be called?
  /**Whether this payment should proceed or not is pending an issue. */
  PENDING_ISSUE = 11,//11-19 is reserved for actionable statuses
  /**This payment will be paid by the next invoice */
  WILL_PAY = 19,
  /**This payment will not be paid. */
  WILL_NOT_PAY = 3,
  /**This payment has already been paid */
  PAID = 4,
  /**The service call associated with this payment has been missed and penalized. */
  MISS = 5,
  MISSED = 5,
  /**The service call associated with this payment has been cancelled. */
  CANCELLED = 6,
  /**The service call associated with this payment was not able to be performed and the store will not pay for it. However, we will not be penalized. */
  UNPERFORMABLE = 7,
  /**This payment was made and later revoked. */
  PAYMENT_REVOKED = 8,
}

let psd = new Map();
for (let [key, value] of Object.entries(PaymentStatus)) {
  psd.set(key, value);
  psd.set(value, key);
}

export const PaymentStatusMap = psd; //Object.keys(PaymentStatus)

/**Current status of a service call.*/
export enum ServiceCallStatus {
  /**This service call is due to be done in the distant future */
  SCHEDULED = 10,
  /**This service call is due to be done in the near future.*///Necessary? I could just search for scheduled and day.
  DUE = 11,//11-19 is reserved for actionable items
  /**This service call has been checked into but not yet checked out of.*/
  IN_PROGRESS = 19,
  /**This service call was missed.*/
  MISSED = 3,
  MISS = 3,
  /**This service call has been completed.*/
  COMPLETED = 4,
  /**This service call has been cancelled.*/
  CANCELLED = 5,
}

export class ServiceCall extends RootDatabaseItem {nu
  constructor(input: Partial<ServiceCall> & { id: string }) {
    super();
    this.status = ServiceCallStatus.SCHEDULED;
    this.amPM = "";
    this.scheduledManually = false;
    this.paidStatus = PaymentStatus.SCHEDULED;
    Object.assign(this, input || {});
  }

  //  constructor(input: ServiceCall) {
  //    Object.assign(this, input);
  //}

  /**ID that is unique for this tenant and item type. For ID W_1_123, this will be 123*/
  uniqueID: string;

  /**What type of item this is*/
  __typename?: string = "ServiceCall";

  /**Current status of this service call*/
  status: ServiceCallStatus;

  /**A list of WorkRecords that belong to this service call.*/
  //@ValidateNested()//Necessary?
  @Type(() => WorkRecord)
  workRecords : WorkRecord[] = [];

  /**A list of issues that belong to this service call.*/
  @Type(() => Issue)

  issues : Issue[] = [];

  /**Whether this service call is assigned to be performed in the AM or PM.*/
  amPM?: "AM" | "PM" | "";

  /**Whether this service call's automatic scheduling was manually overrode. Used by some back end functions.*/
  scheduledManually?: boolean;

  /**The Store that this service call is at.*/
  @Type(() => Store)
  store?: Store;

  /**Time this service call was checked in. Undefined if never checked in.*/
  @Type(() => Date)
  checkIn?: Date;

  /**Time this service call was checked out. Undefined if never checked out.*/
  @Type(() => Date)
  checkOut?: Date;

  /**Day this service call was scheduled for/performed.
   * Stored as integer as number of days since Jan 1 1900 is 1.
   * Dec 31 1899 is 0.
   */
  day?: number;

  /**Date that his service call was scheduled for/performed.
   * Date information only, no time information stored. 1/13/2023
  */
  @Type(() => Date)
  date?: Date;

  /**If there was an issue, this is the ID of that issue. */
  //issueID?: string;

  //employeesPaid: PaymentStatus;

  /**The status of the store paying for the service call.*/
  paidStatus: PaymentStatus;

  /**The amount the store paid for the service call.
   * Null if the store has not paid yet.
   * Can be negative if there was a penalty.
   */
  @Transform(({ value }) => { if (!value) return undefined; return new Big(value) }, { toClassOnly: true })
  paidAmount?: Big;

  /**Tech's notes about this service call.*/
  notes: string;

  /**What type of service this was. A string up to 50 chars long. 
   * @default "Buff Floor"
  */
  serviceType?: "Buff Floor"

}


const urqlLocalResolvers = {
  resolvers: {
    ServiceCall: {
      updatedAt: (parent, args, cache, info) => {
        console.log("at updatedAt resolver");
        console.log(parent);
        console.log(args);
        console.log(cache);
        console.log(info);
        console.log(typeof parent?.updatedAt);

        switch (typeof parent?.updatedAt) {
          case "string": {
            return new Date(parent.updatedAt);
          }
          case "object": {
            return parent.updatedAt;
          }
        }
        return undefined

        /*if (parent?.updatedAt){
          return new Date(parent.updatedAt);
        }
        return undefined;*/
      }
    },
    Query: {
      getServiceCallByID(parent, args, cache, info) {
        console.log("ATE getServiceCallByID")
        console.log(args);
        return { __typename: 'ServiceCall', id: args.id };
      }
    },
  },
  updates: {
    Mutation: {
      getPaginatedServiceCalls(result, args, cache, info) {//Gets another page and then adds it to the base getServiceCalls query cache for that id
        console.log("AT getPaginatedServiceCalls mutation")
        console.log(result);
        console.log(args);
        console.log(cache);
        console.log(info);
        cache.updateQuery({ query: GET_SERVICE_CALLS, variables: { id: args.id } }, data => {
          console.log("at cache.updateQuery");
          console.log(data);
          let ids = new Set<string>(data?.getServiceCalls?.map((item: ServiceCall) => item.id) || []);
          for (let serviceCall of result?.getPaginatedServiceCalls || []) {
            if (!ids.has(serviceCall.id)) {
              data.getServiceCalls.push(serviceCall);
              ids.add(serviceCall.id);
            }
          }
          return data;
        });
      }
    }
  }
}


const modelOutput: ModelFileOutput = {
  ormModels: [ServiceCall],
  urqlLocalResolvers
}
export default modelOutput;
