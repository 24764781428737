//GENERATED AS COPY OF ../../models/RootDatabaseItem.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { Type } from "class-transformer";
import { Contains, MaxLength, MinLength } from "class-validator";
import { Employee, ModelFileOutput, WorkRecord } from "~client/models";
export class DatabaseItemModelMetaData {
    //createHistoricalEntries: boolean = false

    storesChangeHistory: boolean = false
    modelClass: any;
    modelName: string;

    //model: any
    //name: string
    //isHistorical: boolean = false
}
    /**Constructor */
    export abstract class RootDatabaseItem {
    constructor() {
    }

    /**Globally unique ID of this item 
     * Default max length is 30 characters.
     * Default min length is 3 characters.
     * Must contain a _ character.
    */
    @MaxLength(30)
    @MinLength(3)
    @Contains("_")//Atleast one class-validator decorator is required for argument input validation to not error. Issue due to apollo server v4 and TypeGraphQL v2.
    id: string;

    //Not used for right now.
    //deleted? : boolean; 

    /**The ID of the tenant that this item is connected to */
    tenantID?: string

    /**Date item was originally created */
    @Type(() => Date)
    createdAt?: Date

    /**The last time that this item was updated */
    @Type(() => Date)
    updatedAt?: Date

    /**The userID or tenantUserID of the last user to update this item.*/
    updaterID?: string

    /**Version number of this item. Incremented by 1 every time it is updated. */
    version?: number;

    /**The displayName of the last user to update this item */
    //updaterName?: string

    /**The type name of this item */
    __typename?: string

    /**If this is a historical copy of an item, this will be the original non-historical copy's ID. */
    //nonHistoricalID?: string

    /**Metadata for this type of item. */
    __model?: DatabaseItemModelMetaData;


}
const modelOutput: ModelFileOutput = {
    ormModels: [RootDatabaseItem],
    urqlLocalResolvers: {}
}
export default modelOutput;
