//GENERATED AS COPY OF ../../models/WorkRecord.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { RootDatabaseItem } from "./RootDatabaseItem";
import Big from "big.js"
import { Employee, ModelFileOutput, PaymentStatusMap, ServiceCall, Store } from "~client/models";
import { PaymentStatus } from "./ServiceCall";
import { IsEmail, IsPhoneNumber, MinLength } from "class-validator";
import { Transform, Type } from "class-transformer";
export class PayrollDataOutput{

  @Type(() => ServiceCall)
  serviceCalls: ServiceCall[];

  distanceMatrixAddresses: string[];

  distanceMatrix : number[][];

  __typename = "PayrollDataOutput";
  //expenses: 
}
export class WorkRecord extends RootDatabaseItem {
  constructor(input?: Partial<WorkRecord>) {
    super()
    //this.paymentStatus = PaymentStatus.SCHEDULED;
    //this.status = ServiceCallStatus.SCHEDULED;
    //this.worked = false;
    //this.scheduled = false;
    Object.assign(this, input || {});
  }

  __typename?: string = "WorkRecord";

  /**The service call this workRecord is for. */
  @Type(() => ServiceCall)
  serviceCall?: ServiceCall;

  /**The employee who worked or is scheduled to work this service call. */
  @Type(() => Employee)
  employee?: Employee;

  @Type(() => Store)
  store?: Store;

  /**Status of paying the employee for this service call. */
  paymentStatus?: PaymentStatus; //= PaymentStatus.SCHEDULED;

  /**How much the worker was/will be paid for this service call.
   * Can be null for employees on salary.
   */
  @Transform(({ value }) => { if (!value) return undefined; return new Big(value) }, { toClassOnly: true })
  paymentAmount?: Big;

    /**ID that is unique for this tenant and item type. For ID W_1_123, this will be 123*/
  uniqueID?: string;

  /*@Transform(({value}) => { if (!value) return undefined; return new Big(value)}, { toClassOnly: true })
  mileageAmount?: Big;

  @Transform(({value}) => { if (!value) return undefined; return new Big(value)}, { toClassOnly: true })
  mileageRate?: Big;*/

  /**Time the worker checked into this service call */
  @Type(() => Date)
  checkIn?: Date;

  /**Time the worker checked out of this service call */
  @Type(() => Date)
  checkOut?: Date;

  /**Day this workRecord is scheduled, an int representing days since jan 1 1970. */
  day?: number;

  /**If the connected employee actually worked/checked into this service call or was only scheduled to do it.*/
  worked?: boolean;

  /**If the connected employee is/was scheduled to perform this service call when they checked in.*/
  scheduled?: boolean;

  /**Necessary? */
  status?: ServiceCallStatus; //= ServiceCallStatus.SCHEDULED;


  /**Status of a time off request for this WorkRecord. Null if there is no request. */
    //timeOffRequestStatus?: TimeOffStatus; //= ServiceCallStatus.SCHEDULED;
  

  //@IsMap("option111")
  @Transform(({ value }: { value: string[][] }) => {
    return value ? value.reduce((accumulator, [key, value]) => { accumulator[key] = value; return accumulator }, new Object(null)) : undefined
  }, { toClassOnly: true })
  @Transform(({ value }: { value: Record<string, string> }) => value ? Object.entries(value) : undefined, { toPlainOnly: true })
  questions?: Record<string, string>;





}
const modelOutput: ModelFileOutput = {
  ormModels: [WorkRecord],
  urqlLocalResolvers: {}
}
export default modelOutput;
