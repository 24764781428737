//GENERATED AS COPY OF ../../models/Store.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { RootDatabaseItem } from "./RootDatabaseItem";
import Big from "big.js"
import { IsEmail, IsPhoneNumber, MinLength } from "class-validator";
import { Transform, Type } from "class-transformer";
import { Employee, ModelFileOutput, ManagementCompany, ServiceCall, ServiceCallStatus, WorkRecord, PaymentStatus } from "~client/models";
import { GraphQLTimeZones, TimeZones, convertDateToExcel, convertExcelToDate } from "~client/DecORM/utils/TimeZonesEnum";
import { Resolvers, Roles, RolesResolvers } from "~client/configuration";
import { checkIfResolverListsIntersect } from "~client/context/Auth";
export const ALERT_NAMES = ["alertTech","alertAreaManager","alertStateManager","alertGeneralManager"];
export class Store extends RootDatabaseItem {
  constructor(input?: Partial<Store> & { id: string }) {
    super();
    if (input) {
      this.weeklySchedule = ["UNSCHEDULED", "UNSCHEDULED", "UNSCHEDULED", "UNSCHEDULED", "UNSCHEDULED", "UNSCHEDULED", "UNSCHEDULED"];
      this.weeklyAMPM = ["N/W", "N/W", "N/W", "N/W", "N/W", "N/W", "N/W"];
      Object.assign(this, input || {});
    }
  }


  //  constructor(input: Store) {
  //    Object.assign(this, input);
  //}

  /**What this store is called by default in menus. Defaults to #StoreNo. Can be overridden.*/
  @MinLength(3, { message: "Store name must be at least 3 characters long." })
  displayName: string;

  /**ID that is unique for this tenant and item type. For ID W_1_123, this will be 123*/
  uniqueID: string;

  /**Store number*/
  storeNumber: string;

  /**What type of item this is*/
  __typename?: string = "Store";

  /**ID of the tech who is currently generally assigned to this store.*/
  //techID?: string;

  /**Any special notes about this store.*/
  notes: string;

  /**TimeZone enum that this store is located in.*/
  timeZone: TimeZones

  /*
  alertTime: string;

  @Transform(({ value }: { value: string[][] }) => {
    return value ? value.reduce((accumulator, [key, value]) => { accumulator[key] = value; return accumulator }, new Object(null)) : undefined
  }, { toClassOnly: true })
  @Transform(({ value }: { value: Record<string, string> }) => value ? Object.entries(value) : undefined, { toPlainOnly: true })
  alertTimes?: {alertTech?:string, alertAreaManager?:string, alertStateManager?:string, alertGeneralManager?:string} //Record<"alertTech" | "alertAreaManager" | "alertStateManager" | "alertGeneralManager", string>;

  alertTimesList?: string[]
  */

  alertTechTime?: string;

  alertAreaManagerTime?: string;

  alertStateManagerTime?: string;

  alertGeneralManagerTime?: string;


  /**Instructions for how to clock into a Store.
 * If null, then the instructions will be pulled from the management company.
 */
  checkInInstructions?: string;

  /**Instructions on how to clock out of a Store. 
   * If null, then the instructions will be pulled from the management company.
  */
  checkOutInstructions?: string;

  /**Instructions for what to do if there was an issue checking into a Store
   * If null, then the instructions will be pulled from the management company.
  */
  checkInIssueInstructions?: string;

  /**Management company that handles this store */
  //managementCompanyID: string;

  /**IVR Number for this store*/
  //ivrNumber: string;

  /**Store full address*/
  @MinLength(5, { message: "Store address must be at least 5 characters long." })
  address: string;

  //areaManagerID: string;

  /**The management company that is currently managing this store's account.*/
  @Type(() => ManagementCompany)
  managementCompany?: ManagementCompany;

  /**The Employee object for this store's current Area Manager. */
  @Type(() => Employee)
  areaManager?: Employee;

  //stateManagerID: string;

  /**This store's current State Manager. */
  @Type(() => Employee)
  stateManager?: Employee;

  active?: boolean;

  /**Whether late service call alerts will be sent for this store. If true they will not be sent. If false they will be sent.*/
  pauseAlerts: boolean; //= false;//TODO: unnecessary? Service calls should be cancelled if the store is cancelled.

  /**Contact email for this store */
  email: string;

  /**Whether this store is generally worked on a specific day of the week.
   * List of booleans, 7 elements long, where each element represents a day of the week.
   * 0 is Sunday, 6 is Saturday.
   * If the value is true, then the store is generally worked on that day of the week.
  */
  weeklySchedule: (string | "N/A" | "UNSCHEDULED" | "N/W")[] //= ["N/A", "N/A", "N/A", "N/A", "N/A", "N/A", "N/A"] //[false,false,false,false,false,false,false];

  /**Whether service calls are to be performed in the AM or PM for certain days of the week.
   * List of strings, 7 elements long, where each element represents a day of the week.
   * 0 is Sunday, 6 is Saturday.
   */
  weeklyAMPM: ("AM" | "PM" | "" | "N/W")[] //= ["", "", "", "", "", "", ""]

  /**GPS coordinates for this store's
   * [longitude,latitude]
   */
  coordinates?: [number, number];

  /**The amount paid to employees for services.
   * If null, then the amount will be pulled from the employee.*/
  @Transform(({ value }) => { if (!value) return undefined; return new Big(value) }, { toClassOnly: true })
  employeePayRate?: Big;

  /**The amount the store pays for services*/
  @Transform(({ value }) => { if (!value) return undefined; return new Big(value) }, { toClassOnly: true })
  paidRate?: Big;



}


const urqlLocalResolvers = {
  resolvers: {
    Query: {
      getStore(parent, args, cache, info) {
        return { __typename: 'Store', id: args.id };
      }
    }
  }
}


const modelOutput: ModelFileOutput = {
  ormModels: [Store],
  urqlLocalResolvers
}
export default modelOutput;
