//GENERATED AS COPY OF ../../context/Auth.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.

import { IdTokenResult } from "firebase/auth";
import { createContext, MutableRefObject } from "react";
import { Resolvers } from "~client/configuration";
import { GlobalUser, GLOBAL_TENANT_ID, TenantUser } from "~client/configuration";



type HasResolversInput = {
   resolvers?: Resolvers[],
   resolver?: Resolvers | null,
   ownsItemAndHasResolvers?: Resolvers[], ownsItemAndHasResolver?: Resolvers | null,//TODO: implement
   tenantID?: string,
   //user?: GlobalUser | TenantUser, //TODO: implement
   item?: any,
   items?: any[]
} & ({ resolver: Resolvers } | { resolvers: Resolvers[] }) & ({ tenantID: string } | { item: any } | { items: any[] })



export class AuthData {//This data needs to be able to be jsonified...
    constructor({ token = null, isAuthInitialized = false }: { token?: any, isAuthInitialized?: boolean }) {
         if (token) {
             this.token = token
         }
         this.isAuthInitialized = isAuthInitialized
     }


   /**Client only */
     token: MutableRefObject<IdTokenResult | null> = null;

   /**Client only */
     dispatchAuthData: any

   picture?: string;

   /**true if initialized, registered, and is a user. False if guest.*/
   isAuthed: boolean = false;
   /**False until first firebase auth state change*/
   isAuthInitialized: boolean = false;
   /**False until it confirms the user has registered before*/
   isAuthRegistered: boolean = false;//Necessary? If not registered, then isAuthInitialized is also false.

   /**Currently used tenant ID*/
   tenantID?: string;
   /**Currently used tenant name
    * IE, "Company Name"
   */
   tenantsName?: string;

   userID: string

   firebaseData: {
      id: string,
      email: string,
      emailVerified: boolean,
      phoneNumber: string,
      displayName: string,
      photoURL: string
   } = null;

   //isGuest: boolean
   //isInitialized: boolean
   //hasValidToken: boolean
   //currentTenantID?: string;//Necessary?
   //currentTenantName?: string;//Necessary?

   globalUser?: GlobalUser
   tenantUser?: TenantUser//What to call a non global user? localUser? domainUser? tenantUser?

   //user?: User//DEFAULT USER
   users?: (GlobalUser | TenantUser)[] = []//ALL USERS
   usersMap?: Record<string, GlobalUser | TenantUser> = {}//Refers to objects in users above, but with tenantID as key.
   //    checkHasPermission = checkHasPermission

   /**Returns true if the current user has any of the inputted resolvers. False if it does not.
    * @param tenantID Tenant ID to check for. If not provided, will use the tenantID(s) of any provided items. Or, if no items are provided, will use the current tenantID inside auth context. Or, will use global.
    * @param resolvers List of resolvers to check for.
    * @param resolver A single resolver to check for.
    * 
    */

   async getUserForTenantID(this: AuthData, tenantID: string): Promise<GlobalUser | TenantUser> {
      return new Promise<GlobalUser | TenantUser>(async function (this: AuthData, resolve, error) {
         if (tenantID === GLOBAL_TENANT_ID) {
            resolve(this.globalUser);
         } else {
            resolve(this?.usersMap[tenantID] || null);
         }
      }.bind(this));
   }

}

/**Checks if 2 lists of resolvers intersect.
 * Can accept sets or arrays as input.
 * Ideally, the first array should be shorter for performance reasons.
 * Returns true if they intersect, false if they don't.
 */
export function checkIfResolverListsIntersect(list1: Set<number> | number[], list2: Set<number> | number[]) {

   let list2Set = list2 instanceof Array ? new Set(list2) : list2;

   for (let resolver of list1) {
      if (list2Set.has(resolver)) {
         return true;
      }
   }

   return false;
}

export const AuthContext = createContext<AuthData>(new AuthData({}));

