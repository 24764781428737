//GENERATED AS COPY OF ../../models/Employee.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import Big from "big.js"
import { IsEmail, IsPhoneNumber, MinLength } from "class-validator";
import { Transform, Type } from "class-transformer";
import { ModelFileOutput, Store, User } from "~client/models";
import { RootUser } from "./RootUser";
export class Employee extends RootUser {
  constructor(input?: Employee) {
    super();
    if (input) {
      Object.assign(this, input || {});
    }
  }

  //  constructor(input: Employee) {
  //    Object.assign(this, input);
  //}

  /**ID that is unique for this tenant and item type. For ID W_1_123, this will be 123*/
  uniqueID?: string;

  /**What type of item this is*/
  __typename?: string = "Employee";

  /**Any special notes about this Employee.*/
  notes?: string;

  /**Employee full address*/
  address?: string;

  /**Whether late service call alerts will be sent for this Employee. If true they will not be sent. If false they will be sent.*/
  pauseAlerts?: boolean;//TODO This shouldn't be necessary since if someone's on vacation their service calls should be unassigned?

  /**Whether this Employee will actively show up in lists.
   * If set to false, this Employee will not show in lists. But will still show in the database and from relations.
  */
  active?: boolean;

  /**The User object this Employee is authenticated by. */
  @Type(() => User)
  globalUser?: User;

  /**GPS coordinates for this employee's home address.
   * [longitude, latitude]
   */
  coordinates?: [number, number];

  /**The default amount to pay employees for services.
   * Will be overridden if a store has a pay rate set.
   */
  @Transform(({ value }) => { if (!value) return undefined; return new Big(value) }, { toClassOnly: true })
  payRate?: Big;

  /**How much this employee will be paid per mile driven to service calls and back to their home addres. */
  @Transform(({ value }) => { if (!value) return undefined; return new Big(value) }, { toClassOnly: true })
  mileageRate?: Big;

  /**Used for some sorting functions*/
  distance?: number;


}

const urqlLocalResolvers = {
  resolvers: {
    Query: {
      getEmployeeByID(parent, args, cache, info) {
        return { __typename: 'Employee', id: args.id };
      }
    }
  }
}

const modelOutput: ModelFileOutput = {
  ormModels: [Employee],
  urqlLocalResolvers
}
export default modelOutput;
