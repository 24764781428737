//GENERATED AS COPY OF ../../models/index.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { mergeDeep } from '~client/DecORM/utils/General';
//import RootDatabaseItem from "./RootDatabaseItem";
//export {RootDatabaseItem}
//import APIKey from "./APIKey";

//export {SetMetaData} from "./RootDatabaseItem";

//import {RootDatabaseItem as DecORMRootDatabaseItem, RootUser as DecORMRootUser} from "~client/DecORM/models";

//import {RootDatabaseItem} from "./RootDatabaseItem";
//import {RootUser} from "./RootUser";

export interface ModelFileOutput {
    ormModels: any[],
    urqlLocalResolvers: Object,
}

import {User} from "./User";
import { ServiceCall } from './ServiceCall';
import { Store } from './Store';
import { Employee } from './Employee';
import { WorkRecord } from './WorkRecord';
//import { Issue } from './Issue';

var allModelOutputs: ModelFileOutput[] = [];

import {default as rootItemOutput} from './RootDatabaseItem';
allModelOutputs.push(rootItemOutput);
import {default as rootUserOutput} from './RootUser';
allModelOutputs.push(rootUserOutput);

import { default as userOutput } from './User';
allModelOutputs.push(userOutput);
import { default as storeOutput } from './Store';
allModelOutputs.push(storeOutput);
import {default as serviceCallOutput} from './ServiceCall';
allModelOutputs.push(serviceCallOutput);
import {default as employeeOutput} from './Employee';
allModelOutputs.push(employeeOutput);
import {default as workRecordOutput} from './WorkRecord';
allModelOutputs.push(workRecordOutput);
import {default as issueOutput} from './Issue';
allModelOutputs.push(issueOutput);
import { default as managementCompanyOutput } from './ManagementCompany';
allModelOutputs.push(managementCompanyOutput);



//import Balance, { PaymentMethod, Payment }  from "./Balance";
//import APICall from "./APICall";
//export { APIKey, User,Balance,PaymentMethod, APICall, Payment};//APICall,

export const ORMModels = allModelOutputs.reduce((acc,cur)=>{return [...acc, ...cur.ormModels]},[]) 

export * from "./User";
export * from "./ServiceCall";
export * from "./Store";
export * from "./Employee";
export * from "./WorkRecord";
export * from "./ManagementCompany";
export * from "./Issue"

//export {User};

export enum TypeNames{
    "User" = 1,
    "ServiceCall" = 2,
    "Store" = 3,
    "Employee" = 4,
    "WorkRecord" = 5,
    "ManagementCompany" = 6,
    "Issue"=7,
}

//export {User}

export const URQLLocalResolvers = mergeDeep({}, ...allModelOutputs.map((modelOutput) => modelOutput.urqlLocalResolvers));

const modelmap = {}

export const ORMModelMap = modelmap;
