//GENERATED AS COPY OF ../../DecORM/auth/index.tsx. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.


import { devtoolsExchange } from '@urql/devtools';

console.log("AT auth/index.tsx");

/*
PIECES:

Firebase Auth Token
Firebase Auth UserID, used for determining GraphCache DB Name. Stored in localStorage.


var isAuthInitialized = false;

*/

const REGISTER_USER = gql`
query registerUserQuery($firebaseUserID: ID!) {
    register(firebaseUserID: $firebaseUserID) {
        id
        __typename
      globalUser {
        displayName
        createdAt
        email
        firstName
        id
        lastName
        middleName
        phone
        picture
        resolvers
        roles
        __typename
      }
      tenantID
      tokenVersion
      tenantUsers {
        address
        coordinates
        createdAt
        displayName
        email
        firstName
        fullName
        id
        lastName
        phone
        resolvers
        roles
        tenantID
        uniqueID
        updatedAt
        __typename
      }
    }
  }`

class AllAuthData {
    constructor({ firebaseUserID }: { firebaseUserID?: string }) {
        this.firebaseUserID = firebaseUserID;
    }
    token?: IdTokenResult | null = null;
    firebaseUserID?: string | "";
    urqlClient?: Client | null = null;
    readyForGraphQLRequests?: boolean = false;
    authData?: AuthData = null;
}


export function AuthProvider({ children }: { children: React.ReactNode }) {

    console.log("Auth Provider Rendered");

    const [firebaseUserID, setFirebaseUserID] = useState<string>(window?.localStorage?.getItem("firebaseUserID") || "");

    const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);

    const [idToken, setIDToken] = useState<IdTokenResult | null>(null);

    const idTokenRef = useRef<IdTokenResult | null>(null);

    useEffect(() => {
        console.log("at useEffect");
        auth.onAuthStateChanged(async function (user) {
            console.log("Auth State Changed")
            console.log(user);
            if (user) {
                //let idToken = await user.getIdTokenResult(false);
                setFirebaseUserID(user.uid);
                //setIDToken(idToken);
                window.localStorage.setItem("firebaseUserID", user.uid);
                setFirebaseUser(user)
            } else {
                setFirebaseUserID("");
                window.localStorage.setItem("firebaseUserID", "");
                //setIDToken(null);
                setFirebaseUser(null);
            }
        })
        auth.onIdTokenChanged(async (user) => {
            console.log("idTokenChanged")
            console.log("FIREBASE AUTH REFRESHED ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
            if (user) {
                let idToken = await user.getIdTokenResult(false);
                setIDToken(idToken);
                idTokenRef.current = idToken;
            } else {
                setIDToken(null);
                idTokenRef.current = null;
            }
        })

        setInterval(() => {
            if (idTokenRef?.current?.claims?.exp && (parseInt(idTokenRef?.current?.claims?.exp) * 1000) - 600000 < Date.now()) {
                console.log("Token is expired, refreshing automatically with setInterval. Would have expired in " + ((parseInt(idTokenRef?.current?.claims?.exp) - (Date.now() / 1000)) / 60) + " minutes");
                auth?.currentUser?.getIdToken(true);
            }/*else if (idTokenRef?.current?.claims?.exp){
                console.log("setInterval, token will expire in " + ((parseInt(idTokenRef?.current?.claims?.exp) - (Date.now() / 1000)) / 60) + " minutes")
            }else{
                console.log("setInterval, token is not set")
            }*/
        },120000)

    }, [])

    return (
        <AuthWrapper key={firebaseUserID} firebaseUser={firebaseUser} firebaseUserID={firebaseUserID} idToken={idToken} >{children}</AuthWrapper>
    )

}

export function AuthWrapper({ firebaseUser, firebaseUserID, idToken, children }: { firebaseUser: FirebaseUser | null, idToken: IdTokenResult | null, firebaseUserID: string, children: React.ReactNode }) {

    console.log("AuthWrapper Rendered");

    const [authData, dispatchAuthData] = useReducer(AuthReducer, new AuthData({isAuthInitialized: firebaseUserID == ""}));

    const fetchedAuthData = useRef<boolean>(false);

    const allAuthData = useRef<AllAuthData>(new AllAuthData({
        //   token: null,
        firebaseUserID,
        // authData: new AuthData({}),
    }));

    if (allAuthData.current.urqlClient == null) {
        allAuthData.current.urqlClient = makeNewURQLClient(allAuthData);
    }

    if (allAuthData.current.token != idToken) {
        allAuthData.current.token = idToken;
    }

    if (allAuthData.current.token) {
        allAuthData.current.readyForGraphQLRequests = true;
    }

    useEffect(() => {
        if (firebaseUser) { //&& !authData?.firebaseData) {
            console.log("at import firebase user info bef");
            console.log(firebaseUser);
            dispatchAuthData({ type: "importFirebaseUserInfo", value: { user: firebaseUser } });
        }

        if (!fetchedAuthData.current && authData?.firebaseData?.emailVerified && idToken && allAuthData.current.readyForGraphQLRequests) {
            console.log("fetching user info");
            fetchedAuthData.current = true;
            allAuthData.current.urqlClient.query(REGISTER_USER, { firebaseUserID: firebaseUserID }, { requestPolicy: "cache-and-network" }).toPromise().then(async (result) => {
                console.log("done fetching user info");
                console.log(result);

                console.log("bef checking token version");

                let newTokenVersion = result?.data?.register?.tokenVersion || 0;
                let oldTokenVersion = idToken?.claims?.tokenVersion || 0;

                console.log("newTokenVersion: " + newTokenVersion);
                console.log("oldTokenVersion: " + oldTokenVersion);

                if (newTokenVersion > oldTokenVersion) {
                    console.log("Token is out of date, refreshing");
                    for (let i = 0; i < 10; i++) {

                        console.log("Token needs to be updated");
                        let newToken = await firebaseUser?.getIdTokenResult(true);

                        if (newToken?.claims?.tokenVersion || 0 >= result?.data?.register?.tokenVersion) {
                            console.log("Token was refreshed and is now up to date");
                            break
                        }
                        console.log("Token was refreshed but is still out of date, retrying in a second");;
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                    }
                }

                dispatchAuthData({ type: "importGraphQLUserInfo", value: result?.data?.register });

            })
        }

    })

    console.log(allAuthData);
    console.log(authData);

    if (LOGGINGOUT){
        return <p>Logging out...</p>
    }

    return (
        <AuthContext.Provider value={{ ...authData, dispatchAuthData: dispatchAuthData }}>
            <URQLProvider value={allAuthData.current.urqlClient}>
                {children}
            </URQLProvider>
        </AuthContext.Provider>
    )

}

/*
export function AuthContextProvider({ allAuthData, children }: { allAuthData: MutableRefObject<AllAuthData>, children: React.ReactNode }) {

    const [authData, dispatchAuthData] = useReducer(AuthReducer, new AuthData({}));

    const authDataResult = useGraphQLQuery({query:gql`register`,variables:{firebaseUserID:allAuthData.current.firebaseUserID}});



    return (
        {children}
    </AuthContext.Provider>
    )
}*/

import { User as GlobalUser, Employee as TenantUser, URQLLocalResolvers } from "~client/models";
import { authExchange } from "@urql/exchange-auth";
import { retryExchange } from "@urql/exchange-retry";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, onIdTokenChanged, signInWithRedirect, signInWithPopup, IdTokenResult, User as FirebaseUser } from "firebase/auth";
import React, { createContext, MutableRefObject, Ref, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Client, createClient, debugExchange, dedupExchange, errorExchange, fetchExchange, gql, Provider as URQLProvider } from "urql";
//import { firebaseConfig, makeURQLGraphCache, urqlAuthedURL, urqlGuestURL, urqlRetryOptions } from "modules/auth/ClientConfig";

import { AuthContext } from "~client/context/Auth";

import { FIREBASE_CONFIG, GRAPHQL_API_ROUTE, URQL_RETRY_OPTIONS } from "~client/config";

import { AuthData } from "~client/context/Auth";
const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);
export const firebaseAuth = auth;


import { offlineExchange } from '@urql/exchange-graphcache';
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { useGraphQLQuery } from "~client/DecORM/utils/DataConversion";
import { GET_ALL_EMPLOYEES } from "AllGraphQLQueries";
import { processResolvers } from "~client/DecORM/utils/Auth";
import e from 'express';

const googleProvider = new GoogleAuthProvider();

var LOGGINGOUT = false;

const makeNewURQLClient = function (allAuthData: MutableRefObject<AllAuthData>) {

    return createClient({
        //fetchOptions: {
        //   headers: {
        //       "Apollo-Require-Preflight": "true"
        //   }
        // },
        preferGetMethod: false,
        url: GRAPHQL_API_ROUTE,
        exchanges: [
            devtoolsExchange,
            dedupExchange,
            offlineExchange({...URQLLocalResolvers, storage : makeDefaultStorage({idbName: "GraphCache-" + allAuthData.current.firebaseUserID, maxAge: 90})}),
                
            //Old local resolvers. This is now handled in models
                /*
                {
                storage: makeDefaultStorage({
                    idbName: "GraphCache-" + allAuthData.current.firebaseUserID, // The name of the IndexedDB database
                    maxAge: 90, // The maximum age of the persisted data in days
                }),
                keys: {
                    //ServiceCall: data => ""+ data.id,
                    //Store : data => ""+ data.id,
                    //Employee: data => ""+ data.id,
                    //User : data => ""+ data.id,
                },
                resolvers: {
                    ServiceCall:{
                        workRecords(parent, args, cache, info) {
                            return (parent?.workRecords || [])
                        },
                    },
                    Query: {
                      getServiceCallByID(parent, args, cache, info) {
                        return { __typename: 'ServiceCall', id: args.id };
                      },
                      getStore(parent, args, cache, info) {
                        return { __typename: 'Store', id: args.id };
                      },
                    },
                },
            }
            */

            debugExchange,
            errorExchange({
                onError(error) {
                    console.log("URQL GLOBAL ERROR");
                    console.error(error);
                },
            }),

            retryExchange(URQL_RETRY_OPTIONS),

            authExchange({
                didAuthError: ({ error }) => {
                    console.log("at URQL didAuthError");
                    console.log(error);
                    console.log(error.response);
                    console.log(error.response.status);
                    console.log(error.message);
                    console.log(error.graphQLErrors);



   

                    let isAuthError = error.graphQLErrors.some(
                        e => e?.extensions?.code === 401,
                    );

                    console.log("isAuthError");
                    console.log(isAuthError)

                    return isAuthError;
                },


                //This is in docs but typescript complains about it and it doesn't seem to work.
/*                async refreshAuth(){
                    console.log("RUNNING REFRESHAUTH");
await auth.currentUser.getIdTokenResult(true);
await new Promise(r => setTimeout(r, 100));
await new Promise(r => setTimeout(r, 30));
return;
//return allAuthData;
},*/

                getAuth: async (oldtoken) => {
                    console.log("at URQL getAuth");
                    while (allAuthData.current.readyForGraphQLRequests == false) { //(currentAuthData.current.isAuthInitialized == false) {//Wait until auth is initialized.
                        console.log("Waiting for auth to be ready for GraphQL Requests");
                        await new Promise(r => setTimeout(r, 30));
                    }

                    //if (allAuthData.current.token == null) {//Is Guest
                    return allAuthData;
                    //}

                    //if (parseInt(allAuthData.current.token.claims.exp) < (new Date().getTime() / 1000) - 121) {//If token is expired, refresh it.
                    //refresh token;
                    //}




                    /*                  if (currentTokenRef.current == null && currentAuthData.current.isAuthed == false) {//Not logged in.
                                          console.log("getAuth Not Logged In");
                                          return currentTokenRef
                                      }
                  
                                      if (parseInt(currentTokenRef.current.claims.exp) < (new Date().getTime() / 1000) - 121) {//If token is expired, refresh it.
                                      let oldTokenExp = currentTokenRef.current.claims.exp;
                                      console.log("about to refresh token");
                  
                                      console.log(currentAuthData);
                                      console.log(currentAuthData.current);
                  
                                      currentAuthData.current.dispatchAuthData({ type: "refresh" });//Refresh token asynchronously.
                                      while (currentTokenRef.current.claims.exp == oldTokenExp) { //< (new Date().getTime() / 1000) - 121) {//Wait until token was refreshed.
                                          console.log("GETAUTH AWAITING REFRESH");
                                          await new Promise((r) => setTimeout(r, 100));
                                      }
                                      console.log("GETAUTH FINISHED REFRESH");
                                      console.log(parseInt(currentTokenRef.current.claims.exp));
                                      console.log((new Date().getTime() / 1000) - 121)
                                      }
                                      console.log("GETAUTH RETURNING");
                                      console.log(currentTokenRef);
                                      console.log(currentTokenRef.current);
                                      return currentTokenRef; //"Whatever";
                  */


                },

                willAuthError: function ({ authState }) {
                    console.log("at URQL willAuthError");

                    if (allAuthData.current.readyForGraphQLRequests == false) return true;

                    console.log(allAuthData);

                    console.log()

                    if (allAuthData.current?.token && parseInt(allAuthData.current?.token.claims.exp) * 1000 < new Date().getTime() - 60000 * 10 ) return true;

                    console.log("resuming");

                    return false;//Tells it to resume
                },

                addAuthToOperation: function ({
                    authState,
                    operation,
                }) {
                    console.log("at urql addAuthToOperation");


                    if (!allAuthData.current.token) {
                        return operation;
                    }

                    //  return operation//SEND UNAUTHED REQUEST. Now that we're using cookies, this is all we need.

                    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
                    const fetchOptions =
                        typeof operation.context.fetchOptions === 'function'
                            ? operation.context.fetchOptions()
                            : operation.context.fetchOptions || {};

                    return {
                        ...operation,
                        context: {
                            ...operation.context,
                            url: GRAPHQL_API_ROUTE,
                            fetchOptions: {
                                ...fetchOptions,
                                headers: {
                                    ...fetchOptions.headers,
                                    "authtoken": allAuthData.current.token.token //currentTokenRef.current.token,
                                    //"authToken": currentToken //authState.token //authState.token,
                                },
                            },
                        },
                    };
                }
            }),
            fetchExchange],
    })
}

function LOGOUT() {
    //let dbname = "GraphCache"
    //isAuthed = false;//Too Complicated?
    //isAuthInitialized = false;//Too complicated?
    //currentToken = "unauthed";
    //currentTokenExpires = 0;
    //window.localStorage.removeItem("authData");
    //window.localStorage.removeItem("firebaseAuthCache");
    //window.localStorage.removeItem("GraphCacheDBName");
    //newURQLClient = makeNewURQLClient(true);
    //window.localStorage.setItem("logOutClearCache", "true");
    LOGGINGOUT = true;
    window.localStorage.setItem("fireBaseUserID", "");

    auth.signOut().then(function () {
        //window.localStorage.removeItem("authData");
        //window.localStorage.removeItem("firebaseAuthCache");
        //window.localStorage.removeItem("GraphCacheDBName");
        //newURQLClient = makeNewURQLClient(true);
        //window.localStorage.setItem("logOutClearCache", "true");
        console.log("AT LOCATION.RELOAD")
        location.reload();
    })
    //}
}

const AuthReducer = function (authData: AuthData, action: { type: "importFirebaseUserInfo" | "importGraphQLUserInfo" | "refresh" | "updateToken" | "logOut" | "loadFromStorage" | "signInGoogle" | "updateUserList" | "initializeAsGuest", value?: any }): AuthData {
    console.log("authReducer: " + action.type);
    console.log(authData);
    console.log(action);

    if (LOGGINGOUT) {
        return authData;
    }

    switch (action.type) {
        case 'initializeAsGuest': {
            let newAuthData = { ...authData };
            let changed = false;

            if (authData.isAuthInitialized == false) {
                newAuthData.isAuthInitialized = true;
                changed = true;
            }

            if (authData.isAuthed == true) {
                newAuthData.isAuthed = false;
                changed = false;
            }

            //destroyCookie(null, "authToken", { path: "/" });

            if (changed) {
                return newAuthData
            }

            return authData;
        }
        case 'refresh': {
            console.log("at refresh in reducer");
            if (auth.currentUser) {
                console.log("currentuser exists");
                auth.currentUser.getIdToken(true);
            }
            return authData;
        }
        case 'signInGoogle': {
            signInWithPopup(auth, googleProvider);
            //signInWithRedirect(auth, googleProvider);
            return authData;
        }

        case 'importGraphQLUserInfo': {
            let newAuthData = { ...authData };
            let changed = false;

            console.log("at import graphqluserinfo");
            console.log(action.value);

            if (!action.value?.globalUser) {
                alert(JSON.stringify(action.value));
            }

            processResolvers(action.value.globalUser)

            newAuthData.globalUser = action.value.globalUser;
            newAuthData.tenantID = action.value?.tenantID;
            newAuthData.users = [action.value.globalUser];
            newAuthData.usersMap = { [action.value.globalUser.id]: action.value.globalUser}
            for (let tenantUser of action.value.tenantUsers) {
                processResolvers(tenantUser);
                if (tenantUser.tenantID == action.value.tenantID) {
                    newAuthData.tenantUser = tenantUser;
                }
                newAuthData.users.push(tenantUser);
                newAuthData.usersMap[tenantUser.id] = tenantUser;
            }

            newAuthData.isAuthed = true;
            newAuthData.isAuthInitialized = true;
            newAuthData.isAuthRegistered = true;

            return newAuthData;
        }

        case 'importFirebaseUserInfo': {
            console.log("at import fb reducer");
            console.log(action);
            let newAuthData = { ...authData };
            let changed = false;

            let user: FirebaseUser = action.value.user;

            if (user == null && authData.firebaseData != null) {
                console.log("setting firebaseData to null");
                newAuthData.firebaseData = null;
                return newAuthData;
            }

            if (user != null) {
                console.log("user != null");

                if (newAuthData.firebaseData == null) {
                    newAuthData.firebaseData = {};
                    changed = true;
                }

                if (user.displayName != newAuthData.firebaseData.displayName) {
                    newAuthData.firebaseData.displayName = user.displayName;
                    changed = true;
                }

                if (user.email != newAuthData.firebaseData.email) {
                    newAuthData.firebaseData.email = user.email;
                    changed = true;
                }

                if (user.emailVerified != newAuthData.firebaseData.emailVerified) {
                    newAuthData.firebaseData.emailVerified = user.emailVerified;
                    changed = true;
                }

                if (user.phoneNumber != newAuthData.firebaseData.phoneNumber) {
                    newAuthData.firebaseData.phoneNumber = user.phoneNumber;
                    changed = true;
                }

                if (user.photoURL != newAuthData.firebaseData.photoURL) {
                    newAuthData.firebaseData.photoURL = user.photoURL;
                    newAuthData.picture = user.photoURL;
                    changed = true;
                }

            }

            if (changed) {
                console.log("changed");
                return newAuthData;
            }
            console.log("not changed");

            return authData;

        }
        case 'updateToken': {
            let newAuthData = { ...authData };
            let changed = false;

            console.log("at updateToken");
            console.log(action.value);
            console.log(authData);

            /*setCookie(null, "authToken", action.value.token.token, {
                //domain:""//If none, generally defaults to sameSite?
                //httpOnly: true,
                path: "/",
                maxAge: 60 * 60,//seconds
                //expires: new Date//Set it to exp date of token?
                //path: ?not sure
                sameSite: true//strict, lax, none. true is strict. Only allows senging requests to same site.
                //secure:true//requires https connection//Don't do for testing on localhost
                //encode://A function to encode the value?
            });*/

            let idToken: IdTokenResult = action.value.token;
            //let currentTokenRef = action.value.currentTokenRef

            action.value.currentTokenRef.current = idToken;

            if (authData.isAuthed == false) {
                newAuthData.isAuthed = true;
                changed = true;
            }

            if (authData.isAuthInitialized == false) {
                newAuthData.isAuthInitialized = true;
                changed = true;
            }

            if (idToken.claims.userID) {
                if (authData.isAuthRegistered == false) {
                    changed = true;
                    newAuthData.isAuthRegistered = true;
                }
            }

            if (changed) {
                return newAuthData
            }

            return authData
        }
        case "logOut": {
            LOGGINGOUT = true;
            let newAuthData = new AuthData({});

            //if (JSON.stringify(newAuthData) != JSON.stringify(authData)) {
            //saveAuthToStorage(newAuthData);

LOGOUT();

            //currentToken = "unauthed";
            //currentTokenExpires = 0;
            if (typeof window != "undefined") {

                window.localStorage.removeItem("GraphCacheDBName");
                window.localStorage.setItem("logOutClearCache", "true");
                //let DBDeleteRequest = window.indexedDB.deleteDatabase("GraphCache");
            }
         /*   auth.signOut().then(function () {
                if (typeof window != "undefined") {
                    //let DBDeleteRequest = window.indexedDB.deleteDatabase("GraphCache");

                    //window.localStorage.setItem("authData", JSON.stringify(new AuthDataClass()));
                    //window.localStorage.removeItem("authData");
                    //window.location.href = "/logout.html";
                    window.localStorage.removeItem("GraphCacheDBName");
                    //setTimeout(function(){window.localStorage.removeItem("authData"); location.reload();},200)
                }
                console.log("AT LOCATION RELOAD");
               // location.reload();

            })*/

            return newAuthData;

        }

        default: {
            throw Error('Unknown action: ' + action.type);
            return authData
        }
    }

    //return new AuthDataClass();
}


