//GENERATED AS COPY OF ../../models/User.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import {RootUser} from "./RootUser";
import Big from "big.js"
import { IsEmail, IsPhoneNumber, MinLength } from "class-validator";
import { Employee } from "./Employee";
import { GLOBAL_TENANT_ID } from "~client/configuration";
import type { ModelFileOutput } from "~client/models";
import { Type } from "class-transformer";
export class User extends RootUser {
  constructor(input? : Partial<User> ) {
    super();
    Object.assign(this, input || {});
    this.tenantID = GLOBAL_TENANT_ID;
  }

  tenantID: string = GLOBAL_TENANT_ID;

  @Type(() => Employee)
  tenantUsers : Employee[] = [];

  active?: boolean;

  __typename?: string = "User"
}
const modelOutput : ModelFileOutput = {
  ormModels: [User],
  urqlLocalResolvers : {}
}
export default modelOutput;
