import "reflect-metadata"
import '~client/styles/falcon.min.css'
import '~client/styles/extraStyles.css'
import "leaflet/dist/leaflet.css";
import "react-datepicker/dist/react-datepicker.css";

import type { AppProps } from 'next/app'
import Main from "~client/falcon/Main";
//import "helpers/initFA";
import { AuthProvider } from "~client/DecORM/auth" //'modules/auth/AuthWrapper';
//import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import { ModalWrapper } from "~client/DecORM/components/common/ModalProvider";

export default function App({ Component, pageProps }: AppProps) {
  console.log("at app");

  const router = useRouter();

  // let authContext = useContext(AuthContext);

  //if (authContext.isAuthed && authContext.userID == "guest"){
  //send mutation
  //}

  const getLayout = Component.getLayout || ((page) => page)

  //  const authenticationRequired = Component.authenticationRequired || false;


  //{getLayout(
  //  <AnimatePresence mode="wait" initial={false}><Component {...pageProps} key={router.asPath} /></AnimatePresence>)}

  console.log("Router aspath: " + router.asPath)
  console.log(pageProps);
  console.log(Component.displayName);
  console.log(Component.name);

  //key={router.asPath}
  return (
    <Main>
        <AuthProvider>
        <ModalWrapper>
          {getLayout(<Component {...pageProps} key={Component.name} />)}
          {/* <AnimatePresence mode="wait" initial={false}> AnimatePresence causes scrollRestoration to not work... */}
          {/*  <Component {...pageProps}  key={Component.name} /> */}
          {/* </AnimatePresence> */}
          {/*<Component {...pageProps} />*/}
          </ModalWrapper>
        </AuthProvider>
    </Main>)
}
