//GENERATED AS COPY OF ../../models/ManagementCompany.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { RootDatabaseItem } from "./RootDatabaseItem";
import Big from "big.js"
import { IsEmail, IsPhoneNumber, MinLength } from "class-validator";
import { Employee, ModelFileOutput } from "~client/models";
import { GraphQLTimeZones, TimeZones } from "~client/DecORM/utils/TimeZonesEnum";
export class ManagementCompany extends RootDatabaseItem {
  constructor() {
    super();
  }

  //  constructor(input: ManagementCompany) {
  //    Object.assign(this, input);
  //}

  /**What this ManagementCompany is called by default in menus. Defaults to name. Can be overridden.*/
  displayName: string;

  /**This management company's official name*/
  name: string;

  /**ID that is unique for this tenant and item type. For ID W_1_123, this will be 123*/
  uniqueID: string;

  /**What type of item this is*/
  __typename?: string = "ManagementCompany";

  /**Any special notes about this store.*/
  notes: string;

  /**Vendor ID for this management company. Used by some proprietary systems.*/
  vendorID: string;

  /**IVR Number for this managementCompany*/
  //ivrPhoneNumber: string;

  /**Instructions for how to clock into a Store.
   * This will be used if a store associated with this management company does not have its own instructions.
   */
  checkInInstructions?: string;

  /**Instructions on how to clock out of a Store. 
   * This will be used if a store associated with this management company does not have its own instructions.
  */
  checkOutInstructions?: string;

  /**Instructions for what to do if there was an issue checking into a Store
   * This will be used if a store associated with this management company does not have its own instructions.
  */
  checkInIssueInstructions?: string;

  /**IVR Number for this managementCompany*/
  //emergencyPhoneNumber: string;

  /**Contact email for this store */
  emails: string[];

  /**Contact email for this store for equipment issues*/
  equipmentRepairEmail: string[];


}


const urqlLocalResolvers = {
  resolvers: {
    Query: {
      getManagementCompany(parent, args, cache, info) {
        return { __typename: 'ManagementCompany', id: args.id };
      }
    }
  }
}


const modelOutput: ModelFileOutput = {
  ormModels: [ManagementCompany],
  urqlLocalResolvers
}
export default modelOutput;
