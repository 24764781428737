//GENERATED AS COPY OF ../../models/RootUser.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { DatabaseItemModelMetaData } from "./RootDatabaseItem";
import { Contains, IsEmail, IsPhoneNumber, MaxLength, MinLength } from "class-validator";
import { Exclude, Type } from "class-transformer";
import { GLOBAL_TENANT_ID, Resolvers, Roles } from "~client/configuration";
import { ModelFileOutput } from "~client/models";
  export abstract class RootUser {
  constructor() {
  }

  //FROM ROOTDATABASE ITEM
  /**Globally unique ID of this item 
       * Default max length is 30 characters.
       * Default min length is 3 characters.
       * Must contain a _ character.
      */
  @MaxLength(30)
  @MinLength(3)
  @Contains("_")//Atleast one class-validator decorator is required for argument input validation to not error. Issue due to apollo server v4 and TypeGraphQL v2.
  id?: string;

  //Not used for right now.
  //deleted? : boolean; 

  /**When this item was originally created */
  @Type(() => Date)
  createdAt?: Date

  /**The last time that this item was updated */
  @Type(() => Date)
  updatedAt?: Date

  /**The userID of the last user to update this item.*/
  updaterID?: string

  /**Version number of this item. Incremented by 1 every time it is updated. */
  version?: number;

  /**The type name of this item */
  __typename?: string

  __model?: DatabaseItemModelMetaData;
  //END FROM ROOTDATABASE ITEM

  userID?: string;


  @MaxLength(50)
  firstName?: string

  @MaxLength(50)
  middleName?: string

  @MaxLength(50)
  lastName?: string

  @MaxLength(150)
  displayName?: string

  fullName?: string


/*
    console.log("at property array type hydrate");
    console.log(i);
    console.log(typeof i);
    return parseInt(i);
  }),

})*/

  roles?: Roles[]

  rolesSet?: Set<Roles>

  resolvers?: Resolvers[]//null if not calculated yet?  

  resolversSet?: Set<Resolvers>

  tenantID: string

  @MaxLength(200)
  picture?: string

  @IsEmail()
  email?: string

  @IsEmail()
  backupEmail?: string

  @IsPhoneNumber()
  phone?: string

  @IsPhoneNumber()
  backupPhone?: string
}

/**The layout of a JWT claims object */
export interface CustomClaims {
  /**undefined if no selected tenant */
  tenantID?: string;
  /**undefined if not registered */
  userID?: string;
  /**undefined if not registered.
   */

  /**Increments by 1 every time the token is updated. Simplifies letting the client know they need to refresh the token. It can keep refreshing until it gets this number.*/
  tokenVersion?: number;

  users?: {
    /**Unique ID of this GlobalUser or TenantUser */
    id: string,
    /**tenantID of this TenantUser. Or undefined if a GlobalUser. */
    tenantID?: string,
    /**List of roles this User has */
    roles: number[]
  }[]
}
const modelOutput : ModelFileOutput = {
  ormModels: [RootUser],
  urqlLocalResolvers : {}
}
export default modelOutput;
