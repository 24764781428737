import { gql } from "urql";

const MANAGEMENT_COMPANY_FIELDS = `id
displayName
__typename
name
notes
vendorID
checkInInstructions
checkOutInstructions
checkInIssueInstructions
emails
equipmentRepairEmail
`

const WORK_RECORD_FIELDS = `
id
__typename
employee{
  id
  displayName
  __typename
  payRate
}
paymentStatus
status
checkIn
checkOut
day
paymentAmount
questions
worked
scheduled
serviceCall{
  id
  __typename
  serviceType
  amPM
}
store{
  id
  __typename
  storeNumber
  displayName
  employeePayRate
}
`

const EMPLOYEE_FIELDS = `
address
coordinates
displayName
email
firstName
fullName
middleName
id
lastName
payRate
mileageRate
phone
__typename
roles
active`

const STORE_FIELDS = `
address
coordinates
version
updaterID
updatedAt
uniqueID
alertTechTime
alertAreaManagerTime
alertStateManagerTime
alertGeneralManagerTime
employeePayRate
active
managementCompany{
  id
  displayName
  __typename
}
tenantID
storeNumber
displayName
pauseAlerts
notes
email
id
paidRate
createdAt
weeklySchedule
weeklyAMPM
checkInInstructions
checkOutInstructions
checkInIssueInstructions
__typename

areaManager{
  id
  displayName
  email
  phone
  __typename
}

stateManager{
  id
  displayName
  email
  phone
  __typename
}`;

const ISSUE_FIELDS = `
id
__typename
submittedBy{
  id
  __typename
  displayName 
}
store{
  ${STORE_FIELDS}
}
serviceCall{
  id
  __typename
}
questions
issueType
status
files
day
createdAt
longDescription
shortDescription

`

const SERVICE_CALL_FIELDS = `tenantID
createdAt
updatedAt
workRecords{
${WORK_RECORD_FIELDS}
}
issues{
${ISSUE_FIELDS}
}
status
notes
id
day
paidStatus
paidAmount
amPM
date
checkOut
checkIn
__typename
store {
${STORE_FIELDS}
}`

export const GET_ALL_STORES = gql`
query allstores {
  getAllStores {
${STORE_FIELDS}
  }
}`

export const GET_ALL_MANAGEMENT_COMPANIES = gql`
query GetManagementCompanies {
  getManagementCompanies{
${MANAGEMENT_COMPANY_FIELDS}
  }
}`

export const GET_MANAGEMENT_COMPANY = gql`
query GetManagementCompany($id : ID!) {
  getManagementCompany(id: $id){
${MANAGEMENT_COMPANY_FIELDS}
  }
}`

export const GET_STORE_BY_ID = gql`
query getStoreByID($id : ID!) {
  getStore(id: $id) {
${STORE_FIELDS}
  }
}`

export const GET_EMPLOYEE_BY_ID = gql`
query GetEmployeeByID($id : ID!) {
  getEmployeeByID(id: $id) {
${EMPLOYEE_FIELDS}
  }
}`

export const GET_SERVICE_CALL_BY_ID = gql`
query getServiceCallByID($id : ID!) {
  getServiceCallByID(id: $id) {
${SERVICE_CALL_FIELDS}
    }
  }
`

export const GET_ALL_EMPLOYEES = gql`
query GetAllEmployees {
  getAllEmployees {
    address
    coordinates
    displayName
    email
    firstName
    middleName
    fullName
    id
    lastName
    phone
    __typename
  }
}`

export const EDIT_ISSUE = gql`
mutation EditIssue($input : IssueInput!) {
  editIssue(input: $input) {
    ${ISSUE_FIELDS}
  }
}`

export const EDIT_STORE = gql`
mutation EditStore($input : StoreInput!) {
  editStore(input: $input) {
${STORE_FIELDS}
  }
}`

export const EDIT_SERVICE_CALL = gql`
mutation EditServiceCall($input : ServiceCallInput!, $scheduledEmployees: [ID!]) {
  editServiceCall(input: $input, scheduledEmployees: $scheduledEmployees) {
${SERVICE_CALL_FIELDS}
  }
}`

export const EDIT_WORK_RECORD = gql`
mutation EditWorkRecord($input : WorkRecordInput!) {
  editWorkRecord(input: $input) {
${WORK_RECORD_FIELDS}
  }
}`

export const EDIT_MANAGEMENT_COMPANY = gql`
mutation EditManagementCompany($input : ManagementCompanyInput!) {
  editManagementCompany(input: $input) {
${MANAGEMENT_COMPANY_FIELDS}
  }
}`

export const EDIT_EMPLOYEE = gql`
mutation EditEmployee($input : EmployeeInput!) {
  editEmployee(input: $input) {
${EMPLOYEE_FIELDS}
  }
}`

export const CREATE_EMPLOYEE = gql`
mutation CreateEmployee($input : EmployeeInput!) {
  createEmployee(input: $input) {
${EMPLOYEE_FIELDS}
  }
}`

/*export const EDIT_STORE = gql`
mutation EditStore($id : ID!, $weeklySchedule: [String!]!) {
  editStore(id: $id, weeklySchedule: $weeklySchedule ) {
${STORE_FIELDS}
  }
}`*/

export const GET_SERVICE_CALLS_FOR_STORE = gql`
query GetServiceCallsForStore($storeID:ID!, $beginningDay: Int!, $endingDay: Int!) {
  getServiceCallsForStore(beginningDay: $beginningDay, endingDay: $endingDay, storeID: $storeID){
${SERVICE_CALL_FIELDS}
  }
}`

export const GET_SERVICE_CALLS = gql`
query GetServiceCalls($id:ID!) {
  getServiceCalls(id: $id){
${SERVICE_CALL_FIELDS}
  }
}`

export const GET_PAGINATED_SERVICE_CALLS = gql`
mutation GetPaginatedServiceCalls($id:ID, $startDate: Int!, $endDate: Int!) {
  getPaginatedServiceCalls(startDate: $startDate, endDate: $endDate, id: $id){
${SERVICE_CALL_FIELDS}
  }
}`

export const GET_MY_SERVICE_CALLS = gql`
query GetMyServiceCalls {
  getMyServiceCalls {
    ${SERVICE_CALL_FIELDS}
  }
}
 `
/*   checkIn
checkOut
day
amPM
date
id
notes
status
workRecords{
  id
  __typename
  employee{
    id
    displayName
    __typename
  }
  paymentStatus
  checkIn
  checkOut
  paymentAmount
}
tenantID
uniqueID
updatedAt
__typename
store{
  id
  checkInInstructions
  checkOutInstructions
  checkInIssueInstructions
  managementCompany{
    id
    displayName
    __typename
  }
  storeNumber
  displayName
  __typename
}
}
}`
*/

export const CHECK_IN_TO_SERVICE_CALL = gql`
mutation CheckIn($id:ID!) {
  checkInToServiceCall(id: $id){
    ${SERVICE_CALL_FIELDS}
  }
}`

export const CHECK_OUT_OF_SERVICE_CALL = gql`
mutation CheckOut($id:ID!, $issueType: String, $issueQuestions: Map, $issueFiles: [String!], $questions: Map) {
  checkOutOfServiceCall(id: $id, issueType: $issueType, issueQuestions:$issueQuestions, issueFiles:$issueFiles, questions: $questions){
   ${SERVICE_CALL_FIELDS} 
  }
}`

export const GET_WEEKLY_PAYROLL_DATA_FOR_EMPLOYEE = gql`
query GetWeeklyPayrollDataForEmployee($employeeID: ID!, $startingDate: Int!) {
  getWeeklyPayrollDataForEmployee(employeeID: $employeeID, startingDate: $startingDate) {
    serviceCalls {
      ${SERVICE_CALL_FIELDS}
    }
    distanceMatrixAddresses
    distanceMatrix
    __typename
  }
}`

export const GET_EMPLOYEES = gql`
query GetFilteredEmployees($sortByDistanceFrom: String, $roles: [Int!], $active: Boolean) {
  getEmployees(sortByDistanceFrom: $sortByDistanceFrom, roles: $roles, active: $active){
    ${EMPLOYEE_FIELDS}
    distance
  }
}`