//GENERATED AS COPY OF ../../configuration.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.

export const isServer = typeof window === "undefined";
export const isClient = !isServer;
export const isProduction = process.env.NODE_ENV === "production";
export const isDevelopment = !isProduction;
export const isOnline = (typeof window === "undefined") || (window.navigator && window.navigator.onLine);

export type { User as GlobalUser, Employee as TenantUser } from "~client/models"
export const GLOBAL_TENANT_ID = "1";

//if (isServer) throw new Error("ERROR: The client's copy of configuration.ts was loaded by the server!");//This would break page loading, since that loads on the server. Need another way to check
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || "http://localhost:3000";
export const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
export const FIREBASE_APIKEY = process.env.NEXT_PUBLIC_FIREBASE_APIKEY;
export const FIREBASE_AUTH_DOMAIN = FIREBASE_PROJECT_ID + ".firebaseapp.com"
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
export const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
};
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;

export const GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL;
export const GRAPHQL_ERROR_RETRY_INITIAL_DELAY = process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_INITIAL_DELAY;
export const GRAPHQL_ERROR_RETRY_MAX_DELAY = process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_MAX_DELAY;
export const GRAPHQL_ERROR_RETRY_RANDOM_DELAY = process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_RANDOM_DELAY;
export const GRAPHQL_ERROR_RETRY_MAX_NUMBER_ATTEMPTS = process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_MAX_NUMBER_ATTEMPTS;
export const GRAPHQL_API_ROUTE = "/api/graphql";

export const URQL_RETRY_OPTIONS = {
  initialDelayMs: parseInt(process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_INITIAL_DELAY), //1000,
  maxDelayMs: parseInt(process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_MAX_DELAY),
  randomDelay: process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_RANDOM_DELAY === "true",//true
  maxNumberAttempts: parseInt(process.env.NEXT_PUBLIC_GRAPHQL_ERROR_RETRY_MAX_ATTEMPTS),//2
  retryIf: err => err && err.networkError //&& window && window.navigator.onLine,
}
export const CORPORATE_EMAILS = {
  "Diversified": `Jeff Balloon <jballoon@diversifiedm.com>, foodlionwo@diversifiedM.com, Jaclyn Quinones <jquinones@diversifiedm.com>, Lonnie Dubois <ldubois@diversifiedm.com>, cleanpayroll@gmail.com`,
  "Budget": `lorettab@serviceonemaintenance.com, cleanpayroll@gmail.com, megan-serviceone@hotmail.com`,
  "CSG": `sean@csginc.com, diana@csginc.com, cleanpayroll@gmail.com`
}
//~~~~~~~~~AUTH~~~~~~~~

/**List of authorization roles that a user may have.
 * A user can have multiple roles.
 */
export enum Roles {
  /**Baseline employee */
  TECH = 1,

  /**Has some authority over a few stores*/
  AREA_MANAGER = 2,

  /**Has some authority over many stores*/
  STATE_MANAGER = 3,

  /**Can do anything */
  ADMIN = 4,

  /**Can handle payroll related actions */
  PAYROLL = 5,

  /**Can see all stores and see issues related to equipment repair */
  REPAIR = 6,

  /**Permissions applied to area managers for stores that they manage.
   * As well as the related service calls, work records, and issues.
  */
  AREA_MANAGER_MANAGED_STORE = 7,

  /**Permissions applied to state managers for stores that they manage.
   * As well as the related service calls, work records, and issues.
   */
  STATE_MANAGER_MANAGED_STORE = 8,

  /**Permissions applied to techs for stores that they work.*/
  TECH_WORKED_STORE = 9,

  /**Essentially admin but less tech savvy?*/
  GENERAL_MANAGER=10,
}

/**Enum of all actions that a user can perform.
 * A user role consists of a list of these resolver numbers. A user can perform that action if one of their roles possesses one of the needed resolvers.
 */
export enum Resolvers {//Highest Number: 25
  /**Check in to a service call that is scheduled to you*/
  CHECK_IN_TO_OWN_SERVICE_CALL = 1,
  /**Check out of a service call that you're already checked in to*/
  CHECK_OUT_OF_OWN_SERVICE_CALL = 2,

  /**Check in to any service call */
  CHECK_IN_TO_ANY_SERVICE_CALL = 9,
  /**Check out any employee from any service call*/
  CHECK_OUT_OF_ANY_SERVICE_CALL = 10,

  /**View every store */
  VIEW_ALL_STORES = 3,
  /**View an individual store that you're regularly scheduled to work.*/
  VIEW_OWN_STORES = 4,

  /**View any employee */
  VIEW_ALL_EMPLOYEES = 5,
  /**View your own employee object */
  VIEW_OWN_EMPLOYEE = 6,

  /**View every service call that has ever occured */
  VIEW_ALL_SERVICE_CALLS = 7,
  /**View service calls that are either scheduled to, or worked by, you.*/
  VIEW_OWN_SERVICE_CALLS = 8,

  /**View the pay rate for reimbursing vehicle mileage*/
  VIEW_EMPLOYEE_MILEAGE_RATE = 11,

  /**Edit vehicle mileage reimbursement pay rate */
  EDIT_EMPLOYEE_MILEAGE_RATE = 12,

  /**View how much a store pays for services*/
  VIEW_STORE_PAID_RATE = 13,

  /**Edit how much a store pays for services */
  EDIT_STORE_PAID_RATE = 14,

  /**View how much a store pays for services*/
  VIEW_STORE_SERVICE_CALL_PAY_RATE = 21,

  /**Edit how much a store pays for services */
  EDIT_STORE_SERVICE_CALL_PAY_RATE = 22,

  /**View how much an employee is paid in general*/
  VIEW_EMPLOYEE_PAY_RATE = 15,

  /**Edit how much an employee is paid in general*/
  EDIT_EMPLOYEE_PAY_RATE = 16,

  /**View payment status for a work record*/
  VIEW_WORK_RECORD_PAYMENT_STATUS = 17,
  /**Edit payment status for a work record */
  EDIT_WORK_RECORD_PAYMENT_STATUS = 18,

  VIEW_WORK_RECORD_PAYMENT_AMOUNT = 19,
  EDIT_WORK_RECORD_PAYMENT_AMOUNT = 20,

  VIEW_STORE_EMAIL = 23,
  EDIT_STORE_EMAIL = 24,

  VIEW_ALL_MANAGEMENT_COMPANIES = 25,

  EDIT_MANAGEMENT_COMPANY = 26,

  EDIT_STORE = 27,

  VIEW_EMPLOYEE_ADDRESS = 28,

  EDIT_STORE_ALERT_TIME = 29,

  EDIT_SERVICE_CALL_STATUS = 30,

  EDIT_SERVICE_CALL_PAID_STATUS = 31,

  EDIT_SERVICE_CALL_SCHEDULED_EMPLOYEES = 32,

  EDIT_EMPLOYEE = 33,

  EDIT_STORE_SCHEDULE = 34,

  EDIT_SERVICE_CALL = 35,

  VIEW_STORE_PAID_STATUS = 36,
  EDIT_STORE_PAID_STATUS = 37,

  VIEW_ALL_ACTIVE_EMPLOYEES=38,

  VIEW_PAYROLL_PAGE=39,

  EDIT_ISSUE_STATUS=40,
  CREATE_ISSUE=41,
  VIEW_ISSUES=42,
  

  //42
}

const techResolvers = [Resolvers.VIEW_ISSUES,Resolvers.CHECK_IN_TO_OWN_SERVICE_CALL, Resolvers.CHECK_OUT_OF_OWN_SERVICE_CALL, Resolvers.VIEW_OWN_STORES, Resolvers.VIEW_OWN_EMPLOYEE, Resolvers.VIEW_OWN_SERVICE_CALLS]
const managerResolvers = [...techResolvers, Resolvers.CREATE_ISSUE, Resolvers.EDIT_ISSUE_STATUS, Resolvers.VIEW_ALL_ACTIVE_EMPLOYEES, Resolvers.EDIT_SERVICE_CALL_SCHEDULED_EMPLOYEES, Resolvers.EDIT_STORE_ALERT_TIME, Resolvers.VIEW_EMPLOYEE_ADDRESS, Resolvers.VIEW_ALL_MANAGEMENT_COMPANIES, Resolvers.VIEW_ALL_STORES, Resolvers.VIEW_ALL_EMPLOYEES, Resolvers.VIEW_ALL_SERVICE_CALLS, Resolvers.CHECK_IN_TO_ANY_SERVICE_CALL, Resolvers.CHECK_OUT_OF_ANY_SERVICE_CALL]
const payrollResolvers = [...managerResolvers, Resolvers.VIEW_PAYROLL_PAGE, Resolvers.EDIT_WORK_RECORD_PAYMENT_AMOUNT, Resolvers.EDIT_WORK_RECORD_PAYMENT_STATUS, Resolvers.VIEW_STORE_PAID_STATUS, Resolvers.EDIT_STORE_PAID_STATUS, Resolvers.EDIT_SERVICE_CALL, Resolvers.EDIT_STORE_SCHEDULE, Resolvers.EDIT_STORE_SERVICE_CALL_PAY_RATE, Resolvers.EDIT_EMPLOYEE, Resolvers.VIEW_STORE_SERVICE_CALL_PAY_RATE, Resolvers.EDIT_SERVICE_CALL_PAID_STATUS, Resolvers.EDIT_SERVICE_CALL_STATUS, Resolvers.EDIT_STORE, Resolvers.EDIT_MANAGEMENT_COMPANY, Resolvers.VIEW_EMPLOYEE_MILEAGE_RATE, Resolvers.EDIT_EMPLOYEE_MILEAGE_RATE, Resolvers.VIEW_STORE_PAID_RATE, Resolvers.EDIT_STORE_PAID_RATE, Resolvers.VIEW_EMPLOYEE_PAY_RATE, Resolvers.EDIT_EMPLOYEE_PAY_RATE, Resolvers.VIEW_WORK_RECORD_PAYMENT_AMOUNT, Resolvers.VIEW_WORK_RECORD_PAYMENT_STATUS];


export const RolesResolvers: Record<number, number[]> = {
  [Roles.TECH]: techResolvers,
  [Roles.AREA_MANAGER]: managerResolvers,
  [Roles.STATE_MANAGER]: managerResolvers,
  [Roles.PAYROLL]: payrollResolvers,
  [Roles.ADMIN]: payrollResolvers,

  [Roles.AREA_MANAGER_MANAGED_STORE]: [],
  [Roles.STATE_MANAGER_MANAGED_STORE]: [],
  [Roles.TECH_WORKED_STORE]: [],
}






















export const OwnershipResolversSet = new Set([
  Resolvers.VIEW_OWN_STORES,
  Resolvers.VIEW_OWN_EMPLOYEE,
  Resolvers.VIEW_OWN_SERVICE_CALLS,
  Resolvers.CHECK_IN_TO_OWN_SERVICE_CALL,
  Resolvers.CHECK_OUT_OF_OWN_SERVICE_CALL,
])

export const URL_BREADCRUMB_NAMES = {
  "/": "Home",
  "/testPage":"Test Page",
  "/serviceCall": "Service Call",
  "/viewStore": "Store",
  "/viewStore/[id]": "Store",
  "/viewEmployee/[id]": "Employee",
  "/checkOutServiceCall": "Check Out",
  "/viewManagementCompany": "Management Company",
  "/payRoll": "Payroll",
  "/viewEmployee": "Employee",
  "/viewEmployees":"Employees",
  "/viewStores":"Stores"
}

