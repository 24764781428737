//GENERATED AS COPY OF ../../DecORM/utils/General.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.


export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
  /**Does a deep merge of 2 objects
   * @param target The object to merge into
   * @param sources The objects to merge from
   * Target will be mutated. Does not return an immutable copy.
   * */
  export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return mergeDeep(target, ...sources);
  }
