//GENERATED AS COPY OF ../../models/Issue.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.
import { RootDatabaseItem } from "./RootDatabaseItem";
import Big from "big.js"
import { Employee, ModelFileOutput, PaymentStatus, ServiceCall, ServiceCallStatus, Store } from "~client/models";
import { IsArray, IsEmail, IsObject, IsPhoneNumber, MaxLength, MinLength } from "class-validator";
import { Transform, Type } from "class-transformer";


/**Current status of a service call.*/
export enum IssueStatus {
  /**Needs urgent attention. Can be downgraded to ATTENTION after being viewed in many cases. */
  URGENT = 1,

  /**Needs attention, but not urgent. */
  ATTENTION = 2,

  /**Awaiting something, but doesn't necessarily need attention. IE Waiting on something outside our control. */
  PENDING = 3,

  /**This issue has been dealt with and is no longer relevant. */
  RESOLVED = 4,
}


export class Issue extends RootDatabaseItem {
  /**Create a Issue object */
  constructor(input?: Partial<Issue> & { id: string }) {
    super();
    this.shortDescription = "";
    this.longDescription = "";
    this.status = IssueStatus.URGENT;
    Object.assign(this, input || {});
  }

  /**Class name of object */
  __typename: string = "Issue";

  /**A long description of what happened. */
  @MaxLength(50)
  longDescription?: string;

  /**A short succinct description of what happened.
   * Max length of 50 characters.
   */
  @MaxLength(50)
  shortDescription?: string;

  /**The serviceCall that this issue was reported for */
  @Type(() => ServiceCall)
  serviceCall?: ServiceCall;

  /**The store that this issue was reported for.
   * Can be null.
   */
  @Type(() => Store)
  store?: Store;

  /**What employee submitted this issue.
   * Can be null in certain cases if the issue was raised by an automated system.
   */
  @Type(() => Employee)
  submittedBy?: Employee;

  /**Status of this issue */
  status?: IssueStatus;

  /**Type of this issue */
  issueType?: "EQUIPMENT_NEEDS_REPAIR" | "EQUIPMENT_DOWN" | "STORE_MANAGER" | "OUT_OF_PROPANE" | "SLIP_AND_FALL" | "SERVICE_REFUSED" | "CANNOT_MAKE_SERVICE" | "AUTO_SCRUBBER_NEEDS_REPAIR" | "BUFFER_NEEDS_REPAIR"  //IssueType;

  /**Day as days since jan 1 1970 that this issue occured on. */
  day?: number;

  @IsArray()
  @Transform(({ value }: { value: string[][] }) => value ? value.reduce((accumulator, [key, value]) => { accumulator[key] = value; return accumulator }, new Object(null)) : undefined, { toClassOnly: true })
  @Transform(({ value }: { value: Record<string, string> }) => value ? Object.entries(value) : undefined, { toPlainOnly: true })
  questions?: Record<string, string>;

  files?: string[];

  //CONSIDER, make list of userIDs of users that have access or pertinence to this issue?

  static async createIssue(//{longDescription, shortDescription, questions, context}:{longDescription: string, shortDescription?:string, questions: Record<string,string>, context: Context}

    context: Context,

    input: Partial<Issue> & { longDescription: string, issueType: typeof Issue.prototype.issueType }

  ): Promise<Issue> {

    console.log("at createIssue");
    console.log(input);

    if (!input?.longDescription) ThrowGraphQLError({ message: "Long description is required.", errorCode: "BAD_REQUEST" });
    if (!input?.issueType) ThrowGraphQLError({ message: "Issue type is required.", errorCode: "BAD_REQUEST" });
    //TODO will it continue after throwing an error?

    let id = await createNewItemID(context, "I_" + context.auth.tenantID + "_");

    let issue = await context.db.create(Issue, { id }); //new Issue({ id });

    issue.longDescription = input.longDescription;
    issue.issueType = input.issueType;

    issue.status = input?.status || IssueStatus.URGENT;
    if (input?.store) issue.store = input.store;

    if (input?.serviceCall) {
      issue.serviceCall = input.serviceCall
      issue.store = input.serviceCall.store;
      issue.day = input.serviceCall.day;
    } else issue.day = convertDateToExcel(undefined, context.timeZone);

    if (input?.questions) {
      //TODO: Validate questions
      issue.questions = input.questions
    }

    if (input?.submittedBy) issue.submittedBy = input.submittedBy;

    issue.tenantID = context.auth.tenantID;

    if (!input?.shortDescription) {
      if (input.longDescription.length > 48) {
        issue.shortDescription = input.longDescription.substring(0, 47) + "...";
      } else {
        issue.shortDescription = input.longDescription;
      }
    }

    if (input?.files?.length) {
      let s3 = new S3({
        region: "us-east-1"
      })

      issue.files = [];

      //let allUploads = [];

      //TODO: Make this asyncronous so it uploads faster with a lot of different large files.

      for (let fileURL of input?.files) {
        console.log("itting file");

        let uuid = crypto.randomUUID();

        let result = await s3.copyObject({
          Bucket: "clean-slate-innovations",
          Key: "uploads/" + context.auth.tenantID + "/" + uuid + "/" + fileURL.split("/").pop(),
          CopySource: fileURL,
          StorageClass: "STANDARD_IA",
        });
        console.log("s3 res");
        console.log(result);
        //console.log(result.CopyObjectResult?.ETag);

        issue.files.push("https://clean-slate-innovations.s3.amazonaws.com/uploads/" + context.auth.tenantID + "/" + uuid + "/" + encodeURIComponent(fileURL.split("/").pop()));
      }
    }

    console.log("createIssue returning: ", issue);


    // if (issue.issueType == "AUTO_SCRUBBER_NEEDS_REPAIR"){

    let questionsString = "";
    for (let [key, value] of Object.entries(issue.questions)) {
      questionsString += `${key}: ${value}\n`;
    }
    questionsString = questionsString + "\n"

    let fileString = "";
    for (let file of issue?.files || []) {
      fileString += `${file}\n\n`;
    }

    let body = `ISSUE REPORTED\nType:${issue.issueType}\n${issue?.store ? `Store : ${issue?.store?.displayName}\n` : ""}${issue?.submittedBy ? `Reported By : ${issue?.submittedBy?.displayName}\n` : ""}At: ${new Date().toLocaleString("en-US")}\n${questionsString}${fileString}${issue?.serviceCall ? `\nconsole.cleanslateinnovations.com/serviceCall?id=${issue?.serviceCall?.id}` : ""}`;


    let issueType = issue.issueType;

    if (issueType == "AUTO_SCRUBBER_NEEDS_REPAIR" || issueType == "BUFFER_NEEDS_REPAIR" || issueType == "OUT_OF_PROPANE" || issueType == "SERVICE_REFUSED" || issueType == "SLIP_AND_FALL" || issueType == "STORE_MANAGER") {
      await sendTextMessage({ to: "13053040568", body });
      //await sendTextMessage({to:"13867953662", body});//Scott Chancey
      ///await sendTextMessage({to:issue.store?.areaManager?.phone, body});
      }


      if (issueType == "BUFFER_NEEDS_REPAIR") {
        if (issue?.store?.areaManager?.phone != "912-232-2780") {
          //await sendTextMessage({to:"912-232-2780", body});//EQUIPMENT REPAIR
        }
      }

      if (issueType == "SLIP_AND_FALL") {
        if (issue?.store?.areaManager != issue?.store?.stateManager) {
          //await sendTextMessage({to:issue?.store?.stateManager?.phone, body});
        }
      }

      return issue;

      //await context.db.flush();

    }

    //createdAt included. Can be used as submittedAt
  }
const modelOutput: ModelFileOutput = {
  ormModels: [Issue],
  urqlLocalResolvers: {}
}
export default modelOutput;
